<template lang="pug">
settings-page(title="Управление пользователями")
  template(#headerButtons)
    ui-button(
      v-if="hasBlockedUsers"
      type="link"
      :icon-left="UiIconNames.Icon_Lock"
      @click="blockedUsersVisibility = !blockedUsersVisibility"
    ) {{ blockedUsersVisibility ? 'Скрыть заблокированных' : 'Показать заблокированных' }}
    ui-button(
      type="link"
      :icon-left="UiIconNames.Icon_User"
      @click="openInviteUserDialog"
    ) Добавить пользователя

  .user-table
    .user-table__row._header.user-table__border
      .cell._snare Пользователь
      .cell._fio ФИО
      .cell._phone Телефон
      .cell._email Логин
      .cell._access Доступ
      .cell._icon
    ui-loader(v-if="loader")
    template(v-else)
      .user-table__border(
        v-for="user of usersList"
        :key="user.id"
      )
        .user-table__row
          .cell._snare
            span {{ user.fio }}
            span {{ user.phone }}
            span {{ user.login }}
            span.snare-access {{ user.role?.title }}
          .cell._fio {{ user.fio }}
          .cell._phone {{ user.phone }}
          .cell._email {{ user.login }}
          .cell._access(:class="user.role?.id === ROLE_BLOCKED_USER && '_blocked'") {{ user.role?.title }}
          .cell._icon
            icon-button(
              :size="16"
              :icon="UiIconNames.Icon_Connection"
              tooltip-title="Обновить быструю ссылку для входа"
              @click="showUpdateLinkDialog(user)"
            )
            icon-button(
              :size="16"
              :icon="UiIconNames.Icon_Mail"
              tooltip-title="Редактировать email пользователя"
              @click="openEditMailDialog(user)"
            )
            icon-button(
              :size="16"
              :icon="UiIconNames.Icon_Edit"
              tooltip-title="Редактировать данные пользователя"
              @click="openEditUserDialog(user)"
            )
            icon-button(
              v-if="isTmManager"
              :size="16"
              :icon="UiIconNames.Icon_Notification"
              tooltip-title="Настройки уведомлений"
              @click="openUserNotificationInfo(user)"
            )

  .access_info(v-if="enableAccessControl && usersRolesList && usersRolesList.length > 0")
    .title.bold Уровни доступа:
    .role(v-for="role of usersRolesList" :key="role.title")
      span.bold {{ role.title }}
      |
      | - {{ role.description }}

email-modifier-dialog(
  v-if="showEmailModifierDialog"
  v-model:show="showEmailModifierDialog"
  :user="activeUser"
)
user-modifier-dialog(
  v-if="showUserModifierDialog"
  v-model:show="showUserModifierDialog"
  :user="activeUser"
  :is-create="isCreate"
)
confirm-link-dialog(
  v-if="showConfirmLinkDialog"
  v-model:show="showConfirmLinkDialog"
  :user="activeUser"
)
user-notification-dialog(
  v-if="isTmManager && showUserNotificationDialog"
  v-model:show="showUserNotificationDialog"
  :user="activeUser"
)
</template>

<script lang="ts">
import { defineComponent, computed, ref, defineAsyncComponent } from "vue";
import { storeToRefs } from "pinia";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";

import SettingsPage from "@/components/pages/settings/common/SettingsPage.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import UiLoader from "@/components/ui/loader/UiLoader.vue";
import UiButton from "@/components/ui/button/UiButton.vue";

import type { ManagerI } from "@/stores/auth/UserInterface";

import useManualsStore from "@/stores/manuals/useManualsStore";
import useUsersStore from "@/stores/users/useUsersStore";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import { ROLE_BLOCKED_USER } from "@/const/roles";

export default defineComponent({
  name: "UserManagement",
  components: {
    UiButton,
    UiLoader,
    IconButton,
    SettingsPage,
    UserModifierDialog: defineAsyncComponent(() => import("@/components/pages/settings/userManagementPage/UserModifierDialog.vue")),
    EmailModifierDialog: defineAsyncComponent(() => import("@/components/pages/settings/userManagementPage/EmailModifierDialog.vue")),
    UserNotificationDialog: defineAsyncComponent(() => import("@/components/pages/settings/userManagementPage/UserNotificationDialog.vue")),
    ConfirmLinkDialog: defineAsyncComponent(() => import("@/components/pages/settings/userManagementPage/ConfirmLinkDialog.vue")),
  },
  setup() {

    const activeUser = ref<ManagerI|undefined>();

    const { getQueryDot } = useSuperAccount();
    const { me, isTmManager, enableAccessControl } = useUserAccess();

    const usersStore = useUsersStore();
    const { getUsers } = usersStore;
    const {
      usersList: allUsers,
      usersLoading: loader,
      activeUsers,
    } = storeToRefs(usersStore);

    const isCreate = ref(false);
    const blockedUsersVisibility = ref(true);

    const showConfirmLinkDialog = ref(false);
    const showUserNotificationDialog = ref(false);
    const showUserModifierDialog = ref(false);
    const showEmailModifierDialog = ref(false);

    const manualsStore = useManualsStore()
    const { usersRolesList } = storeToRefs(manualsStore)

    if (enableAccessControl.value) manualsStore.manuals.getRoles()

    getUsers(getQueryDot())

    const usersList = computed<ManagerI[]>(() => blockedUsersVisibility.value ? allUsers.value : activeUsers.value)
    const hasBlockedUsers = computed<boolean>(() => activeUsers.value.length !== allUsers.value.length)

    function showUpdateLinkDialog(u: ManagerI) {
      activeUser.value = u;
      showConfirmLinkDialog.value = true;
    }

    function openInviteUserDialog() {
      isCreate.value = true;
      showUserModifierDialog.value = true;
    }

    function openEditUserDialog(user: ManagerI) {
      isCreate.value = false;
      activeUser.value = user;
      showUserModifierDialog.value = true;
    }

    function openEditMailDialog(user: ManagerI) {
      activeUser.value = user;
      showEmailModifierDialog.value = true;
    }

    function openUserNotificationInfo(u: ManagerI) {
      activeUser.value = u;
      showUserNotificationDialog.value = true;
    }

    return {
      me,
      isCreate,
      isTmManager,
      activeUser,
      loader,
      usersList,
      usersRolesList,
      showConfirmLinkDialog,
      showUserModifierDialog,
      showEmailModifierDialog,
      blockedUsersVisibility,
      hasBlockedUsers,
      showUserNotificationDialog,
      enableAccessControl,
      openInviteUserDialog,
      openEditUserDialog,
      openEditMailDialog,
      showUpdateLinkDialog,
      openUserNotificationInfo,
      UiIconNames,
      ROLE_BLOCKED_USER,
    }
  },
});
</script>

<style scoped lang="scss">
$cell-padding: 18px 12px;
$icon-padding: 18px 0;

.user-table {
  background-color: white;
  border-radius: 4px;
}

.user-table__border {
  border-bottom: 1px solid #F1F0F0;

  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.user-table__row {
  display: grid;
  font-size: 14px;
  line-height: 18px;
  grid-template-columns: auto 156px 260px 236px 136px;
  padding: 0 8px;

  .cell {
    padding: $cell-padding;
    overflow-wrap: anywhere;
    box-sizing: border-box;

    &._snare {
      display: none;

      .snare-access {
        display: none;
      }
    };

    &._blocked {
      color: var(--main-red-color);
    }

    &._icon {
      display: flex;
      justify-content: right;
      color: var(--main-color-blue);

      :deep(.attention) {
        color: var(--main-red-color) !important;
      }
    }
  }

  &._header {
    //background-color: #F3F4F5;
    font-weight: bold;
  }

  &._active {
    background-color: #F5F7FA;
  }
}

.access_info {
  display: flex;
  flex-flow: column;
  gap: 12px;

  font-size: 13px;
  padding: 12px 24px;

  .title {
    font-size: 14px;
  }

  .bold {
    font-weight: bold;
  }
}

@media (max-width: 1080px) {
  .user-table__row {
    grid-template-columns: auto 120px 180px 144px 108px;
  }
}

@media (max-width: 880px) {
  .user-table__row {
    grid-template-columns: auto 144px 108px;

    .cell {
      &._snare {
        display: flex;
        flex-flow: column;
        gap: 2px;

        .snare-access {
          display: none;
        }
      };
      &._fio { display: none; };
      &._phone { display: none; };
      &._email { display: none; };
    }
  }
}

@media (max-width: 620px) {
  .user-table__row {
    grid-template-columns: auto 108px;

    .cell {
      &._snare {
        .snare-access {
          display: flex;
        }
      };
      &._access { display: none; };
    }
  }
}
</style>
